import {
  Button,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import { green } from "@material-ui/core/colors"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import Filter from "bad-words-plus"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Carousel from "react-material-ui-carousel"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import styled from "styled-components"

const Container = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TitleWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`
  max-width: 100%;
  max-height: 150px;
`

const TextWrapper = styled.div`
  padding: 10px;
  flex: 1;
`

// const rainbow = keyframes`
// 		0% {
// 			filter: hue-rotate(0deg);
// 		}
// 		100% {
// 			filter: hue-rotate(-360deg);
// 		}
// 			`;

// const PageLink = styled(Link)`
// 	background-image: -webkit-linear-gradient(
// 		120deg,
// 		rgb(255, 0, 0),
// 		rgb(0, 0, 255)
// 	);
// 	-webkit-text-fill-color: transparent;
// 	-webkit-background-clip: text;
// 	background-clip: text;
// 	color: transparent;
// 	animation: 2s linear 0s infinite normal none running ${rainbow};
// `;

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button)

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const CustomCheckbox = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#5800ff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const useStyles = makeStyles((theme) => ({
  tc: {
    color: "blue",
    cursor: "pointer",
    /* textDecoration: "underline", */
  },
  instaFeed: {
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  imgSpacer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
}))

const BaseForm = ({ current }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [issue, setIssue] = useState("")
  const [desc, setDesc] = useState("")
  const [tc, setTC] = useState(false)
  const [issueList, setIssueList] = useState([])
  const [instaPostList, setInstaPostlist] = useState([])
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openTC, setOpenTC] = useState(false)
  const [isSupplier, setIsSupplier] = useState(pathname.includes("suppliers"))
  const [id, setID] = useState()
  const filter = new Filter({ firstLetter: true })

  const handleCloseSuccess = () => {
    setOpenSuccess(false)
  }

  const handleCloseTC = () => {
    setOpenTC(false)
  }

  const validate = () => {
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const emailValid = emailRE.test(String(email).toLowerCase())
    if (issue === "") {
      toast.error("Please select an Issue")
      return 0
    } else if (!desc.length) {
      toast.error("Please provide some description")
      return 0
    } else if (!fname.length) {
      toast.error("First Name cannot be empty")
      return 0
    } else if (!lname.length) {
      toast.error("Last Name cannot be empty")
      return 0
    } else if (!emailValid) {
      toast.error("Invalid Email")
      return 0
    } else if (phone.length !== 10) {
      toast.error("Phone Number should be of 10 digits")
      return 0
    } else if (zipcode === "") {
      toast.error("Zip Code cannot be empty")
      return 0
    } else if (!tc) {
      toast.error("Please accept the Terms & Conditions")
      return 0
    }
    return 1
    /* return !isNaN(phone) && !isNaN(zipcode) && phone.length === 10; */
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      const profaneCount = desc
        .split(" ")
        .map((profane) => profane.includes("*"))
        .filter((profane) => profane).length
      fetch(
        `${process.env.REACT_APP_API}${pathname}${
          !isSupplier ? "/demands" : ""
        }`,
        {
          method: "POST",
          body: JSON.stringify({
            fname,
            lname,
            email,
            phone,
            zipcode,
            issue,
            desc,
            profanes: profaneCount,
          }),
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          setFname("")
          setLname("")
          setEmail("")
          setPhone("")
          setZipcode("")
          setIssue("")
          setDesc("")
          setTC(false)
          setID(data.id)
          setOpenSuccess(true)
          // if (!isSupplier)
          // 	history.push("/ga58/recommendations", {
          // 		params: { id: data.id, query: { zipcode, issue } },
          // 	});
        })
    } else {
      toast.error("Sorry!!! But Something Is Wrong Please Try Again")
    }
  }

  useEffect(() => {
    setIsSupplier(pathname.includes("suppliers"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/ga58/issues`)
      .then((resp) => resp.json())
      .then((data) => {
        setIssueList(data)
      })
    fetch(`${process.env.REACT_APP_API}/ga58/instaPosts`)
      .then((resp) => resp.json())
      .then((data) => {
        setInstaPostlist(data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>trebal: GA58</title>
      </Helmet>
      <Container>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit} className="bg">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TitleWrapper>
                        <ImgWrapper>
                          <Img src="/images/cannon-park-4880.jpg" alt="" />
                        </ImgWrapper>
                        <TextWrapper>
                          <Typography
                            variant="h3"
                            align="center"
                            color="secondary"
                          >
                            {isSupplier ? "Provide" : "Get"} Assistance
                          </Typography>
                          <Typography variant="h4" align="center">
                            Representative Park Cannon
                            <br />
                            58th District, Atlanta, Georgia
                          </Typography>
                        </TextWrapper>
                      </TitleWrapper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="issue-label">
                          What is this about?
                        </InputLabel>
                        <Select
                          labelId="issue-label"
                          id="What is this about?"
                          label="What is this about?"
                          value={issue}
                          /* required */
                          onChange={(e) => setIssue(e.target.value)}
                        >
                          <MenuItem value="" disabled>
                            Please Choose an Issue
                          </MenuItem>
                          {issueList.map((issue) => (
                            <MenuItem value={issue.id} key={issue.id}>
                              {issue.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="How can we help?"
                        placeholder="Please keep it short so we can respond faster!"
                        size="small"
                        variant="outlined"
                        value={desc}
                        multiline
                        rows={6}
                        onChange={(e) => setDesc(filter.clean(e.target.value))}
                        /* required */
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        size="small"
                        variant="outlined"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        /* required */
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        size="small"
                        variant="outlined"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        /* required */
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        size="small"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        /* required */
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        size="small"
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        /* required */
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Zip Code"
                        size="small"
                        variant="outlined"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                        /* required */
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={tc}
                            onChange={() => setTC(!tc)}
                            /* color='primary' */
                          />
                        }
                        style={{ margin: 0 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" component="span">
                        I agree to the{" "}
                        <span
                          onClick={() => {
                            setOpenTC(true)
                          }}
                          className={classes.tc}
                        >
                          Terms & Conditions.
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={6}>
                      <ColorButton
                        color="secondary"
                        fullWidth
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </ColorButton>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
									<Grid container justify='flex-end'>
										<TextWrapper>
											<Typography
												variant='subtitle2'
												align='center'
											>
												{isSupplier
													? "Do You Need"
													: "Can You Provide"}{" "}
												Any Assistance? Click{" "}
												<PageLink
													to={() => {
														if (
															isSupplier
														)
															return "/";
														else
															return "/suppliers";
													}}
												>
													HERE
												</PageLink>
											</Typography>
										</TextWrapper>
									</Grid>
								</Grid> */}
                <Grid container>
                  <Grid item xs={12}>
                    <TextWrapper>
                      <Typography variant="subtitle2" align="center">
                        © 2023{" "}
                        <a
                          href="mailto:trebalservices@gmail.com"
                          style={{
                            display: "inline",
                          }}
                        >
                          trebal
                        </a>
                        . All Rights Reserved.
                      </Typography>
                      {/* <Typography
												variant='subtitle2'
												align='center'
											>
												Developed by trebal
											</Typography> */}
                    </TextWrapper>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          {instaPostList.length !== 0 && (
            <Grid item xs={12} md={4} className={classes.imgSpacer}>
              <Carousel
                interval={10000}
                animation="slide"
                indicators={false}
                reverseEdgeAnimationDirection={false}
                className="bg"
              >
                {instaPostList?.map((instaPost) => (
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="column"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="h4" align="center" color="secondary">
                        {instaPost.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ImgWrapper>
                        <img src={instaPost.img} alt="dummy" />
                      </ImgWrapper>
                    </Grid>
                    {instaPost.link && (
                      <Grid item>
                        <ColorButton
                          color="secondary"
                          variant="contained"
                          href={instaPost.link}
                          target="_blank"
                        >
                          View Post
                        </ColorButton>
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Carousel>
            </Grid>
          )}
        </Grid>
      </Container>

      <Dialog
        onClose={handleCloseSuccess}
        aria-labelledby="customized-dialog-title"
        open={openSuccess}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseSuccess}>
          {isSupplier ? "Service" : "Request"} Submitted
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Thank you for submitting your {isSupplier ? "service" : "request"}.
            We will be in touch with you shortly.
            <br />
            Your Identification ID: {id}
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog open={openTC} onClose={handleCloseTC} scroll="paper">
        <DialogTitle>Terms & Conditions</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText tabIndex={-1}>
            Information you provide will be used by representatives of the site
            only to provide assistance.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default BaseForm
