import ReactDOM from "react-dom"
import App from "./App"
import GlobalStyles from "./globalStyles"
import React from "react"
ReactDOM.render(
  <>
    {/* <React.StrictMode> */}
    <GlobalStyles />
    <App />
    {/* </React.StrictMode> */}
  </>,
  document.getElementById("root")
)
