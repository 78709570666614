import { useMediaQuery } from "@material-ui/core";
import * as React from "react";
import {
	BulkDeleteWithConfirmButton,
	Create,
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	EditButton,
	List,
	Pagination,
	required,
	Show,
	SimpleForm,
	SimpleList,
	SimpleShowLayout,
	TextField,
	TextInput,
	TopToolbar,
	useNotify,
	useRedirect,
	useRefresh,
} from "react-admin";

export const IssueList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			pagination={<Pagination {...props} rowsPerPageOptions={[]} />}
			bulkActionButtons={<BulkDeleteWithConfirmButton />}
		>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.title}
					linkType='show'
				/>
			) : (
				<Datagrid rowClick='show'>
					<TextField source='id' />
					<TextField source='title' />
					<EditButton
						base='/suppliers'
						label={isSmall ? "" : "Edit"}
					/>
					<DeleteWithConfirmButton
						base='/suppliers'
						label={isSmall ? "" : "Delete"}
					/>
				</Datagrid>
			)}
		</List>
	);
};

const IssueTitle = ({ record }) => {
	return <span>Issue {record ? `"${record.title}"` : ""}</span>;
};

export const IssueEdit = (props) => (
	<Edit title={<IssueTitle />} {...props}>
		<SimpleForm>
			<TextInput
				label='Issue Name'
				source='title'
				fullWidth={true}
				validate={required()}
			/>
		</SimpleForm>
	</Edit>
);

export const IssueCreate = (props) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();
	const onSuccess = () => {
		notify(`Issue Added`);
		redirect("/issues");
		refresh();
	};
	return (
		<Create onSuccess={onSuccess} {...props}>
			<SimpleForm>
				<TextInput
					label='Issue Name'
					source='title'
					fullWidth={true}
					validate={required()}
				/>
			</SimpleForm>
		</Create>
	);
};

const IssueShowActions = ({ basePath, data, resource }) => (
	<TopToolbar>
		<DeleteWithConfirmButton
			basePath={basePath}
			record={data}
			resource={resource}
		/>
	</TopToolbar>
);

export const IssueShow = (props) => (
	<Show {...props} title={<IssueTitle />} actions={<IssueShowActions />}>
		<SimpleShowLayout>
			<TextField source='id' />
			<TextField source='title' />
		</SimpleShowLayout>
	</Show>
);
