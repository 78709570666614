import * as React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { useState, useEffect } from "react"
import { PieChart } from "@mui/x-charts/PieChart"
import Stack from "@mui/material/Stack"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"

const provider = window.location.pathname.split("#")[0].split("/")[1]

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  media: {
    height: 200,
    backgroundSize: "contain",
  },
})

export default function MediaCard() {
  const classes = useStyles()
  const [chartData, setChartData] = useState([])
  const [zipCodeData, setZipCodeData] = useState([])
  const [isHidden, setIsHidden] = React.useState(false)
  const [isHidden2, setIsHidden2] = React.useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/${provider}/demands/stats`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(console.error())
        }
        return response.json()
      })
      .then((data) => {
        const issueStats = data.issueStats || {}
        const zipStats = data.zipStats || {}
        const customColourPalette = [
          "#5E81EE",
          "#1338AE",
          "#EBF1FE",
          "#212429",
          "#495057",
          "#ACB5BD",
          "#DDE2E5",
          // some more
          "#8dd3c7",
          "#ffffb3",
          "#bebada",
          "#fb8072",
          "#80b1d3",
          "#fdb462",
          "#b3de69",
          "#fccde5",
          "#d9d9d9",
          "#bc80bd",
          "#ccebc5",
          "#ffed6f",
        ]

        const updatedChartData = issueStats.map((item) => ({
          ...item,
          color:
            customColourPalette[
              Math.floor(Math.random() * customColourPalette.length)
            ],
        }))

        setChartData(updatedChartData)

        const zipCodeData = zipStats.map((item) => ({
          ...item,
          zipStats,
        }))
        setZipCodeData(zipCodeData)
      })
      .catch((error) => {
        console.error("Error fetching the data:", error)
      })
  }, [])

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image="/images/headerImage1.svg"
        title="trebal"
      />
      <CardContent>
        <Typography gutterBottom variant="h3" component="h1" align="center">
          Analytics and Insights
        </Typography>
        <Stack direction="row" width="100%" textAlign="center" spacing={2}>
          {chartData && chartData.length > 0 ? (
            <>
              <div className="pieChartDiv">
                <Stack>
                  <FormControlLabel
                    checked={isHidden}
                    control={
                      <Checkbox
                        onChange={(event) => setIsHidden(event.target.checked)}
                      />
                    }
                    label="Click here to hide the legend"
                    labelPlacement="end"
                  />
                  <PieChart
                    className="pieChartInnerCircle"
                    series={[
                      {
                        data: chartData,
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: -90,
                        endAngle: 360,
                        cx: 170,
                        cy: 10,
                        sortingValues: "desc",
                      },
                    ]}
                    width={600}
                    height={250}
                    margin={{ top: 100, bottom: 450, left: 100, right: 100 }}
                    slotProps={{
                      legend: {
                        hidden: isHidden,
                        // direction: "column",
                        // position: { vertical: "middle", horizontal: "right" },
                        direction: "row",
                        vertical: "bottom",
                        horizontal: "middle",
                        padding: 0,
                        markGap: 8,
                        itemGap: 5,
                        itemMarkWidth: 20,
                        itemMarkHeight: 20,
                        labelStyle: {
                          fill: "black",
                          fontSize: 17,
                        },
                      },
                    }}
                  />
                </Stack>
              </div>

              <div className="pieChartDiv">
                <Stack>
                  <FormControlLabel
                    checked={isHidden2}
                    control={
                      <Checkbox
                        onChange={(event) => setIsHidden2(event.target.checked)}
                      />
                    }
                    label="Click here to hide the legend"
                    labelPlacement="end"
                  />
                  <PieChart
                    className="pieChartInnerCircle"
                    series={[
                      {
                        data: zipCodeData,
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        startAngle: -90,
                        endAngle: 360,
                        cx: 170,
                        cy: 10,
                        sortingValues: "desc",
                      },
                    ]}
                    width={600}
                    height={250}
                    margin={{ top: 100, bottom: 400, left: 100, right: 100 }}
                    slotProps={{
                      legend: {
                        hidden: isHidden2,
                        // direction: "column",
                        // position: { vertical: "middle", horizontal: "right" },
                        direction: "row",
                        vertical: "bottom",
                        horizontal: "middle",
                        padding: 0,
                        markGap: 8,
                        itemGap: 5,
                        itemMarkWidth: 20,
                        itemMarkHeight: 20,
                        labelStyle: {
                          fill: "black",
                          fontSize: 17,
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
            </>
          ) : (
            <Typography
              variant="h5"
              color="textSecondary"
              component="h4"
              align="center"
            >
              When insights are available they will appear here.
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
