import decodeJwt from "jwt-decode"
const provider = window.location.pathname.split("#")[0].split("/")[1]
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // called when the user attempts to log in
  login: async ({ username, password }) => {
    const request = new Request(
      `${process.env.REACT_APP_API}/${provider}/login`,
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    )
    try {
      const response = await fetch(request)
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }
      const { token } = await response.json()
      return token ? localStorage.setItem("token", token) : null
    } catch {
      throw new Error("Network error")
    }
    // localStorage.setItem("username", username);
    // // accept all username/password combinations
    // return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token")
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem("token")
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    if (localStorage.getItem("token") === null) {
      return Promise.reject()
    }
    return Promise.resolve(decodeJwt(localStorage.getItem("token")).sub.role)
  },
  getIdentity: () => {
    if (localStorage.getItem("token") === null) {
      return Promise.reject()
    }
    return Promise.resolve({
      fullName: decodeJwt(localStorage.getItem("token")).sub.username,
      id: decodeJwt(localStorage.getItem("token")).sub.id,
      avatar: "temp",
      role: decodeJwt(localStorage.getItem("token")).sub.role,
    })
  },
}
