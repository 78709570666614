/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react"
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart"
import Stack from "@mui/material/Stack"
import "../NewForm.css"
import {
  CCarousel,
  CCarouselItem,
  CImage,
  CCarouselCaption,
} from "@coreui/react"
import "@coreui/coreui/dist/css/coreui.min.css"
import "bootstrap/dist/css/bootstrap.min.css"

function FormRight() {
  // ------------------chart data
  const provider = window.location.pathname.split("#")[0].split("/")[1]
  const [chartData, setChartData] = useState([])
  const [TOTAL, setTOTAL] = useState(1)
  const [index, setIndex] = useState(1)

  useEffect(() => {
    setIndex((prevIndex, nextIndex) => {
      if (prevIndex || nextIndex === 0) {
        prevIndex = 1
      }
    })
  }, [])

  // Define getArcLabel function
  const getArcLabel = (params) => {
    const percent = params.value / TOTAL
    return `${(percent * 100).toFixed(0)}%`
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/${provider}/demands/stats`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(console.error())
        }
        return response.json()
      })

      .then((data) => {
        const issueStats = data.issueStats || {}
        const customColourPalette = [
          "#5E81EE",
          "#1338AE",
          "#EBF1FE",
          "#212429",
          "#495057",
          "#ACB5BD",
          "#DDE2E5",
          // some more
          "#8dd3c7",
          "#ffffb3",
          "#bebada",
          "#fb8072",
          "#80b1d3",
          "#fdb462",
          "#b3de69",
          "#fccde5",
          "#d9d9d9",
          "#bc80bd",
          "#ccebc5",
          "#ffed6f",
        ]

        const updatedChartData = issueStats.map((item) => ({
          ...item,
          color:
            customColourPalette[
              Math.floor(Math.random() * customColourPalette.length)
            ],
        }))

        const totalValue = updatedChartData
          .map((item) => item.value)
          .reduce((a, b) => a + b, 0)
        setTOTAL(totalValue)

        setChartData(updatedChartData)
      })
      .catch((error) => {
        console.error("Error fetching the data:", error)
      })
  }, [])

  // ------------------chart data

  //-------------------Insta post
  const [announcementData, setAnnouncementData] = useState({
    title: "",
    eventDate: "",
    eventTime: "",
    img: "",
    link: "",
    addToCalendar: "",
  })
  //-------------------Insta post

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/${provider}/instaPosts`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(console.error())
        }
        return res.json()
      })
      .then((data) => {
        setAnnouncementData(data)
      })
      .catch((error) => {
        console.log("Error fetching data:", error)
      })
  }, [])

  return (
    <>
      <div className="rightContainer">
        {/*------------------------- pie chart */}
        <div className="announcements">
          <h5>Community Priorities 2024</h5>
          <br />

          <p>
            Here are some of the top community concerns from spring 2022. Below
            you can find additional resources:
          </p>
          <div className="pieChartDiv">
            <Stack>
              <PieChart
                className="pieChartInnerCircle css-13aj3tc-MuiChartsSurface-root"
                series={[
                  {
                    data: chartData,
                    outerRadius: 100,
                    arcLabel: getArcLabel,
                    // css properties
                    innerRadius: 30,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 360,
                    cx: 160,
                    valueFormatter: getArcLabel,
                    sortingValues: "desc",
                  },
                ]}
                width={500}
                height={250}
                slotProps={{
                  legend: {
                    hidden: false,
                    direction: "column",
                    padding: -20,
                    labelStyle: {
                      fontSize: 14,
                      fill: "black",
                    },
                  },
                }}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "#212429",
                    fontSize: 12,
                    fontWeight: "bold",
                  },
                }}
              />
            </Stack>
          </div>
        </div>
        {/*------------------------- pie chart */}
        <br />
        <br />
        <CCarousel controls dark className="instaPostFlex " activeIndex={index}>
          <div>
            <h5>Announcements</h5>
            <br />
            <p>
              All upcoming events and things to look out for are posted below
            </p>
            <br />
          </div>

          {Array.isArray(announcementData) &&
            announcementData.map((eventData, index) => (
              <CCarouselItem key={index} className="announcementContent">
                <div className="frameSize">
                  <CImage
                    className="imageSize"
                    src={eventData.img}
                    alt={`slide ${index + 1}`}
                  />

                  <CCarouselCaption className="d-none d-md-block centered-text">
                    <a target="blank" className="nolinkUnderline boldImpInfo ">
                      {eventData.date}
                      <span>&nbsp; at &nbsp; </span>
                      {eventData.time}
                    </a>
                    <br />
                    <a
                      href={eventData.link}
                      target="blank"
                      className="linkUnderline boldImpInfo"
                    >
                      Link to the Event
                    </a>
                    <br />
                    <a
                      href={eventData.addToCalendar}
                      target="blank"
                      className="nolinkUnderline boldImpInfo"
                    >
                      Add to Calendar
                    </a>
                  </CCarouselCaption>
                </div>
              </CCarouselItem>
            ))}
        </CCarousel>
        {/* ====== */}
      </div>
    </>
  )
}

export default FormRight
