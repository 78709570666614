/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import * as React from "react"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import Filter from "bad-words-plus"
import { Grid, TextField, TextareaAutosize } from "@material-ui/core"
import { maxLength } from "react-admin"

const provider = window.location.pathname.split("#")[0].split("/")[1]

const ITEM_HEIGHT = 70
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function FormLeft() {
  const [issueList, setIssueList] = useState([])
  const [issue, setIssue] = useState("")
  const [desc, setDesc] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [profaneCount, setProfaneCount] = useState(0)
  const filter = new Filter({ firstLetter: true })

  const ga58Zipcode = [
    "00000",
    "30002",
    "30030",
    "30032",
    "30033",
    "30067",
    "30079",
    "30080",
    "30084",
    "30303",
    "30305",
    "30306",
    "30307",
    "30308",
    "30309",
    "30310",
    "30311",
    "30312",
    "30313",
    "30314",
    "30315",
    "30316",
    "30317",
    "30318",
    "30319",
    "30324",
    "30326",
    "30327",
    "30328",
    "30329",
    "30337",
    "30338",
    "30339",
    "30340",
    "30341",
    "30342",
    "30344",
    "30345",
    "30346",
    "30354",
    "30360",
    "30363",
  ]

  const validate = (issue, desc, zipcode) => {
    if (issue === "") {
      toast.error("Please select an Issue")
      return 0
    } else if (zipcode.length !== 5) {
      toast.error("Incorrect Zip Code")
      return 0
    }
    return 1
  }

  //---------issues
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/${provider}/issues`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(console.error())
        }
        return res.json()
      })
      .then((data) => {
        setIssueList(data)
        // setIssue(data)
      })
      .catch((error) => {
        console.log("Error fetching data:", error)
      })
  }, [])

  //--------HandleSubmit
  const [formData, setFormData] = useState({
    issueList: "",
    issue: "",
    desc: "",
    zipcode: "",
    profaneCount: 0,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate(formData.issue, formData.desc, formData.zipcode)) {
      const profaneCount = formData.desc
        .split(" ")
        .map((profane) => profane.includes("*"))
        .filter((profane) => profane).length
      formData["profaneCount"] = profaneCount
      fetch(`${process.env.REACT_APP_API}/${provider}/demands`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(formData),
      })
        .then((resp) => {
          if (!resp.ok) {
            throw new Error("Network response was not ok", console.error())
          }
          return resp.json()
        })

        .then((data) => {
          alert("Data sent sucessfully. Your issue Id is: " + data.id)

          setFormData({
            issueList: "",
            issue: "",
            desc: "",
            zipcode: "",
          })
        })
        .catch((error) => {
          console.error("Error sendig data:", error)
        })
    }
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   setFormData({
  //     ...FormData,
  //     [name]: value,
  //   })
  // }

  const maxLengthCheck = (object) => {
    if (object.target.value.length === 6) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      )
    }
  }

  const handleIssueListChange = (e) => {
    setFormData({ ...formData, issueList: e.target.value })
  }

  const handleIssueChange = (e) => {
    setFormData({ ...formData, issue: e.target.value })
  }

  const handleDescChange = (e) => {
    const newDesc = e.target.value
    setFormData({
      ...formData,
      desc: newDesc.trim() !== "" ? filter.clean(newDesc) : newDesc,
    })
  }

  const handleZipcodeChange = (e) => {
    setFormData({ ...formData, zipcode: e.target.value })
  }

  /*======================================
Return Block
========================================*/
  return (
    <>
      <div className="leftContainer">
        <h1>
          Park Cannon believes hearing from her constituents makes the community
          a better place!
        </h1>
        <br />
        <p>
          Fill out the form below if you have an individual or community concern
          you would like brought to her attention.
        </p>

        <div className="messageBox">
          <form onSubmit={handleSubmit}>
            {/*-------------------------------- Issue*/}
            <h6>
              Issue <span>*</span>
            </h6>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      labelId="issue-label"
                      id="What is this about?"
                      value={formData.issue.id}
                      name="issue"
                      className="formControlCustom"
                      onChange={handleIssueChange}
                      MenuProps={MenuProps}

                      // onChange={handleChange}
                      // onChange={(e) => setIssue(e.target.value)}
                    >
                      <MenuItem value="" disabled>
                        Please Choose an Issue
                      </MenuItem>
                      {issueList &&
                        issueList.map((issue) => (
                          <MenuItem
                            className="css-6hp17o-MuiList-root-MuiMenu-list"
                            value={issue}
                            key={issue.id}
                          >
                            {issue.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <div className="selectedDropdown">
                      {formData.issue.title}
                    </div>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <br /> <br />
            {/*-------------------------------Issue*/}
            {/*-------------------------------Message*/}
            <h6>Message</h6>
            <Grid item xs={12} md={12} lg={12}>
              <TextareaAutosize
                className="formControlCustom"
                label="Issue Description"
                placeholder="Dear Park Cannon, I am writing to express my concern about..."
                size="small"
                variant="outlined"
                value={formData.desc}
                name="desc"
                minRows={10}
                onChange={handleDescChange}
                // onChange={handleChange}
                // onChange={(e) => setDesc(e.target.value)}
              />
              {desc}
            </Grid>
            <br /> <br />
            {/*-------------------------------Message*/}
            <div className="zipCodeFlex">
              <div className="flexDisplay">
                <h6>
                  Zip Code <span>*</span>
                </h6>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          labelId="zipcode-label"
                          id="zipcode"
                          value={formData.zipcode}
                          name="zipcode"
                          className="formControlCustom"
                          onChange={handleZipcodeChange}
                          MenuProps={MenuProps}
                        >
                          <MenuItem value="" disabled>
                            Please Choose your Zipcode
                          </MenuItem>
                          {ga58Zipcode &&
                            ga58Zipcode.map((zip) => (
                              <MenuItem value={zip} key={zipcode}>
                                {zip}
                              </MenuItem>
                            ))}
                        </Select>
                        <div className="selectedDropdown">
                          {formData.zipcode}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/* ----------------zipcode */}
            {/* <p className="zipCodeFinder">
              You Can Look Up Your Full Zip Code <span></span>
              <a
                className="zipcodeFiner_Here"
                target="_blank"
                href="https://intownelite.com/atlanta-zip-codes/"
                rel="noreferrer"
              >
                Here
              </a>
            </p> */}
            <br /> <br />
            <button type="submit" className="submitButton">
              Submit
            </button>
          </form>

          <br />
          <p className="noteThis">
            For your safety, please refrain from sharing any sensitive
            information. We don't require any identifying details to receive and
            display your concerns on this interactive page. If you require
            individual assistance, you can reach out to Park's staff directly at
            <span>
              <a href="mailto:district58affairs@gmail.com">
                <span> </span> district58affairs@gmail.com
              </a>
            </span>
            .
          </p>
        </div>
      </div>
    </>
  )
}
export default FormLeft
