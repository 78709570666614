import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		/* width */
		&::-webkit-scrollbar {
			width: 10px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #cecece;
			border-radius: 10px;
		}
		
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #a7a7a7; 
			border-radius: 10px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #818181; 
		}
	}



	a{
		text-decoration: none;
	}

	p {
		color: #10285d;
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 15px;
		font-weight: normal;
	}
	h1, h2, h3, h4, h5 {
		color: #673ab7;
		margin-top: 0px;
		font-style: normal;
		font-weight: 400;
	}
	ul, ol, li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	h3 {
		font-weight: 900;
	}

	.MuiFormControl-root {
		// background: #ffffffe3 !important;
		border-radius: 6px;
	}

	.Mui-focused {
		color: #651fff ;
	}

	.bg {
		padding: 20px;
		background: #ffffffa8;
		border-radius: 10px;
	}

	body {
		margin: 0;
		padding: 0;
		
		& canvas {
			position: absolute;
			top: 0;
			z-index: -1;
		}
	}

	#root {
		background: url('/images/Atlanta_Downtown_Skyline.jpg');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		min-height: 100vh;
		height: 100%;
		display: flex;
		// justify-content: space-around;
		// align-items: center;
	}

	.MuiFormLabel-asterisk, .MuiInputLabel-asterisk{
		display: none;
	}

	.MuiTypography-colorSecondary {
		color: #ff0000d9;
	}

	.MuiTable-root caption {
		font-weight: 700;
	}

	span.MuiTypography-root.MuiTypography-body1 {
		color: black;
		font-weight: 900;
	  }
	  
	  label.css-j204z7-MuiFormControlLabel-root {
		justify-content: center !important;
		padding-bottom: 5%;
		margin: 0 !important;
	  }
	  .css-6hp17o-MuiList-root-MuiMenu-list {
		max-height: 400px;
	  }
	  
	  .css-13aj3tc-MuiChartsSurface-root {
		padding: 0% 2% 5%;
	  }

	  //-----------------
	  span.MuiTypography-root.MuiTypography-body1 {
		color: rgb(0, 0, 0) !important;
		font-weight: 900 !important;
	  }
	  
	  label.css-j204z7-MuiFormControlLabel-root {
		justify-content: center !important;
		padding-bottom: 5% !important;
		margin: 0 !important;
	  }
	  .css-6hp17o-MuiList-root-MuiMenu-list {
		max-height: 400px !important;
	  }
	  
	  .css-13aj3tc-MuiChartsSurface-root {
		padding: 0% 2% 5% !important;
	  }
	  //------------------
	  
	  @media only screen and (max-width: 768px) {
		.css-6hp17o-MuiList-root-MuiMenu-list {
			max-height: 400px;
		  }
		
		  .css-2dkrk1-MuiStack-root {
			flex-wrap: wrap;
			justify-content: center;
		  }
		  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
			font-size: 20px !important;
		  }
//-------------------
		  .css-6hp17o-MuiList-root-MuiMenu-list {
			max-height: 400px !important;
		  }
		
		  .css-2dkrk1-MuiStack-root {
			flex-wrap: wrap !important;
			justify-content: center !important;
		  }
		  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
			font-size: 20px !important;
		  }
//-------------------

	  }
`

export default GlobalStyles
