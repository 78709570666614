// eslint-disable-next-line no-unused-vars
// import logo from "./logo.svg"
import "../Form/NewForm.css"
import HeaderImage from "./FormComponents/HeaderImage"
import FormLeft from "./FormComponents/FormLeft"
import FormRight from "./FormComponents/FormRight"
import FooterResources from "./FormComponents/FooterResources"
function NewForm() {
  return (
    <>
      <div className="heroContainer">
        <div>
          <HeaderImage />
        </div>
        <div className="formStatContainer">
          <div className="formLeft">
            <FormLeft />
          </div>
          <div className="statsRight">
            <FormRight />
          </div>
        </div>
        <div>
          <FooterResources />
        </div>
      </div>
    </>
  )
}

export default NewForm
