import { useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as React from "react"
import {
  BulkDeleteWithConfirmButton,
  Create,
  Datagrid,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  List,
  Pagination,
  required,
  Show,
  SimpleForm,
  SimpleList,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin"

export const InstaPostList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"))
  return (
    <List
      {...props}
      pagination={<Pagination {...props} rowsPerPageOptions={[]} />}
      bulkActionButtons={<BulkDeleteWithConfirmButton />}
    >
      {isSmall ? (
        <SimpleList primaryText={(record) => record.title} linkType="show" />
      ) : (
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="date" />
          <TextField source="time" />
          <EditButton base="/suppliers" label={isSmall ? "" : "Edit"} />
          <DeleteWithConfirmButton
            base="/suppliers"
            label={isSmall ? "" : "Delete"}
          />
        </Datagrid>
      )}
    </List>
  )
}

const InstaPostTitle = ({ record }) => {
  return <span>Event Title {record ? `"${record.title}"` : ""}</span>
}

export const InstaPostEdit = (props) => (
  <Edit title={<InstaPostTitle />} {...props}>
    <SimpleForm>
      <TextInput
        label="Event Name"
        source="title"
        fullWidth={true}
        validate={required()}
      />
      <TextInput
        label="Event Image Url"
        source="img"
        fullWidth={true}
        validate={required()}
      />
      <TextInput
        label="Event Link"
        source="link"
        fullWidth={true}
        validate={required()}
      />
      <TextInput
        source="date"
        type="date"
        label="Event Date"
        fullWidth={true}
        validate={required()}
      />
      <TextInput
        source="time"
        label="Event Time"
        fullWidth={true}
        validate={required()}
        type="time"
      />
    </SimpleForm>
  </Edit>
)

export const InstaPostCreate = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = () => {
    notify(`Event Added`)
    redirect("/instaPosts")
    refresh()
  }
  return (
    <Create onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput
          label="Event Name"
          source="title"
          fullWidth={true}
          validate={required()}
        />
        <TextInput
          label="Event Image Url"
          source="img"
          fullWidth={true}
          validate={required()}
        />
        <TextInput
          label="Event Link"
          source="link"
          fullWidth={true}
          validate={required()}
        />
        <TextInput
          source="date"
          label="Event Date"
          fullWidth={true}
          validate={required()}
          type="date"
        />
        <TextInput
          source="time"
          label="Event Time"
          fullWidth={true}
          validate={required()}
          type="time"
        />
      </SimpleForm>
    </Create>
  )
}

const useStyles = makeStyles({
  root: {
    width: 0,
  },
})

const InstaPostShowActions = ({ basePath, data, resource }) =>
  data ? (
    <TopToolbar>
      <DeleteWithConfirmButton
        basePath={basePath}
        record={data}
        resource={resource}
      />
      <EditButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
  ) : null

export const InstaPostShow = (props) => {
  const classes = useStyles()
  return (
    <Show
      {...props}
      title={<InstaPostTitle />}
      actions={<InstaPostShowActions />}
    >
      <SimpleShowLayout classes={{ root: classes.root }}>
        <TextField source="id" />
        <TextField source="title" />
        <FunctionField
          label="Url"
          render={(record) =>
            record.img.length > window.innerWidth * 0.08
              ? `${record.img.slice(0, window.innerWidth * 0.08)}...`
              : record.img
          }
        />
        <ImageField source="img" title="Event Title" />
        <TextField source="link" title="Event Link" />
        <TextField source="date" title="Event Date" />
        <TextField source="time" title="Event Time" />
      </SimpleShowLayout>
    </Show>
  )
}
