function FooterResources() {
  // Define an array of objects containing link data
  const links = [
    { url: "https://www.parkcannon58.com/", name: "Park Cannon Platform" },
    {
      url: "https://atldot.atlantaga.gov/services-2/permits-2/street-renaming#:~:text=A%20street%20renaming%20and%2For%20dedication%20application%20is%20needed%20if,the%20city's%20commissioner%20of%20its",
      name: "Street renaming",
    },
    {
      url: "https://atldot.atlantaga.gov/services-2/permits-2/street-renaming#:~:text=A%20street%20renaming%20and%2For%20dedication%20application%20is%20needed%20if,the%20city's%20commissioner%20of%20its",
      name: "Road Naming Requests",
    },
    {
      url: "https://dol.georgia.gov/get-unemployment-assistance",
      name: "Unemployment Assistance",
    },
    {
      url: "https://www.fultoncountyga.gov/inside-fulton-county/fulton-county-departments/registration-and-elections/find-my-polling-site",
      name: "Local Voting Locations",
    },
    { url: "https://dfcs.georgia.gov/snap-food-stamps", name: "SNAP Benefits" },
    {
      url: "https://sos.ga.gov/page/election-calendar-and-events",
      name: "Election Calendar & Events",
    },
    {
      url: "https://www.legis.ga.gov/",
      name: "Upcoming Events at the Capitol",
    },
    {
      url: "https://www.legis.ga.gov/legislation/all",
      name: "Upcoming Legislation for Current Session",
    },
  ]

  return (
    <div className="footerContainer">
      <h5>Resources</h5>
      <div className="footerResources">
        {/* Map over the links array to generate anchor tags */}
        {links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            className="linkUnderline"
            target="_blank"
            rel="noreferrer"
          >
            {link.name}
          </a>
        ))}
      </div>
    </div>
  )
}

export default FooterResources
