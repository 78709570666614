import React from "react"
import headerImage1 from "./headerImage/headerImage1.svg"

function headerImage() {
  return (
    <>
      <div className="wrapper">
        <img className="headerImage" src={headerImage1} alt="" />

        <h2 className="headerText">We Want to Hear from You!</h2>
      </div>
    </>
  )
}

export default headerImage
