/* eslint-disable import/no-anonymous-default-export */
import decodeJwt from "jwt-decode"
import { stringify } from "query-string"
import { fetchUtils } from "react-admin"

const apiUrl = process.env.REACT_APP_API
const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms * 1000))
}
const provider = window.location.pathname.split("#")[0].split("/")[1]

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    // Constructing the query with pagination, sorting, and filtering
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    }

    const url = `${apiUrl}/${provider}/${resource}?${stringify(query)}`

    return fetch(url, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // Filtering data based on conditions (specific to "questions" resource and Admin role)
        if (resource === "questions" && params.filter.role === "Admin") {
          const filteredData = res.filter(
            (data) => data.userId === params.filter.userId
          )
          return filteredData
        }

        return {
          data: res,
          total: res.length,
        }
      })
      .catch((err) => {
        console.log("Error occurred during fetch:", err)
        throw err
      })
  },

  getOne: (resource, params) => {
    const { id } = params

    if (!id) {
      return Promise.reject(new Error("Missing ID parameter"))
    }

    const url = `${apiUrl}/${provider}/${resource}/${id}`

    const token = localStorage.getItem("token")
    if (!token) {
      return Promise.reject(new Error("Token not found"))
    }

    return fetch(url, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok")
        }
        return res.json()
      })
      .then((data) => ({
        data,
      }))
      .catch((error) => {
        console.error("Error fetching data:", error)
        throw error // Propagate the error for the caller to handle
      })
  },

  getMany: (resource, params) => {
    const { ids } = params

    if (!Array.isArray(ids) || ids.length === 0) {
      return Promise.reject(new Error("Invalid or empty IDs array"))
    }

    const query = {
      filter: JSON.stringify({ id: ids }),
    }

    const url = `${apiUrl}/${provider}/${resource}?${stringify(query)}`

    const token = localStorage.getItem("token")
    if (!token) {
      return Promise.reject(new Error("Token not found"))
    }

    return fetch(url, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok")
        }
        return res.json()
      })
      .then((data) => ({ data }))
      .catch((error) => {
        console.error("Error fetching data:", error)
        throw error // Propagate the error for the caller to handle
      })
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination || { page: 1, perPage: 10 } // Set default pagination values if not provided
    const { field, order } = params.sort || { field: "id", order: "ASC" } // Set default sorting values if not provided

    const filter = { ...params.filter }
    filter[params.target] = params.id

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(filter),
    }

    const url = `${apiUrl}/${provider}/${resource}?${stringify(query)}`

    return fetch(url, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok")
        }
        return res.json()
      })
      .then((res) => ({
        data: res,
        total: res.length, // Consider modifying this depending on the API response structure
      }))
      .catch((error) => {
        console.error("Error fetching data:", error)
        throw error
      })
  },

  // update: (resource, params) => {
  //   const url = `${apiUrl}/${provider}/${resource}/${params.id}`

  //   return (
  //     fetch(url, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json;charset=utf-8",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //       body: JSON.stringify(params.data),
  //     })
  //       .then((res) => {
  //         if (!res.ok) {
  //           throw new Error("Failed to update resource")
  //         }
  //         return res.json()
  //       })
  //       .then((data) => {
  //         return data
  //       })
  //       .catch((error) => {
  //         console.error("Error updating resource:", error)
  //         throw error
  //       })
  //   )
  // },

  update: async (resource, params) => {
    const url = `${apiUrl}/${provider}/${resource}/${params.id}`
    const { json } = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(params.data),
    })
    return { data: json }
  },

  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        fetch(`${apiUrl}/${provider}/${resource}/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(params.data),
        }).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to update resource with ID: ${id}`)
          }
          return res.json()
        })
      )
    )
      .then((responses) => ({
        data: responses.map((json) => json), // Assuming each response is the updated resource
      }))
      .catch((error) => {
        console.error("Error updating resources:", error)
        throw error // Propagate the error for the caller to handle
      }),

  create: (resource, params) => {
    return fetch(`${apiUrl}/${provider}/${resource}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(params.data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to create resource")
        }
        return res.json()
      })
      .then((res) => {
        if (!res._id) {
          throw new Error("Invalid ID returned from server")
        }
        return {
          data: { ...params.data, id: res._id },
        }
      })
      .catch((error) => {
        console.error("Error creating resource:", error)
        throw error // Propagate the error for the caller to handle
      })
  },

  createMany: async (resource, params) => {
    try {
      const countResp = await fetch(`${apiUrl}/${provider}/${resource}/count`)
      const countData = await countResp.json()
      let count = countData

      // Simulate a delay of 1ms using sleep function
      await sleep(1)

      const userId = decodeJwt(localStorage.getItem("token")).sub.id

      const createPromises = params.data.map(async (data, index) => {
        if (data.hasOwnProperty("suggestedQues")) {
          data["suggestedQues"] = JSON.parse(data["suggestedQues"])
        }
        if (!data.hasOwnProperty("userId")) {
          data["userId"] = userId
        }
        data["_id"] = count + index + 1
        data["import"] = true

        const response = await fetch(`${apiUrl}/${provider}/${resource}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(data),
        })

        if (!response.ok) {
          throw new Error(`Failed to create resource at index ${index}`)
        }

        return response.json()
      })

      const responses = await Promise.all(createPromises)

      return {
        data: responses,
      }
    } catch (error) {
      console.error("Error creating resources:", error)
      throw error
    }
  },

  delete: (resource, params) => {
    const url = `${apiUrl}/${provider}/${resource}/${params.id}`

    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to delete resource")
        }
        return res.json()
      })
      .then((data) => ({ data }))
      .catch((error) => {
        console.error("Error deleting resource:", error)
        throw error
      })
  },

  deleteMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        fetch(`${apiUrl}/${provider}/${resource}/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to delete resource with ID: ${id}`)
          }
          return res.json()
        })
      )
    )
      .then((responses) => ({
        data: responses.map((json) => json.id),
      }))
      .catch((error) => {
        console.error("Error deleting resources:", error)
        throw error
      })
  },
}
