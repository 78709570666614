import { useMediaQuery } from "@material-ui/core";
import * as React from "react";
import {
	BulkDeleteWithConfirmButton,
	Create,
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	EditButton,
	EmailField,
	List,
	Pagination,
	PasswordInput, required,
	Show,
	SimpleForm,
	SimpleList,
	SimpleShowLayout,
	TextField,
	TextInput,
	TopToolbar,
	useNotify,
	useRedirect,
	useRefresh
} from "react-admin";

export const UserList = (props) => {
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		<List
			{...props}
			pagination={<Pagination {...props} rowsPerPageOptions={[]} />}
			bulkActionButtons={<BulkDeleteWithConfirmButton />}
		>
			{isSmall ? (
				<SimpleList
					primaryText={(record) => record.name}
					secondaryText={(record) => record.email}
					linkType='show'
				/>
			) : (
				<Datagrid rowClick='show'>
					<TextField source='id' />
					<TextField source='username' />
					<EmailField source='email' />
					<EditButton base='/users' />
					<DeleteWithConfirmButton base='/users' />
				</Datagrid>
			)}
		</List>
	);
};

const UserTitle = ({ record }) => {
	return <span>User {record ? `"${record.username}"` : ""}</span>;
};

export const UserEdit = (props) => (
	<Edit title={<UserTitle />} {...props}>
		<SimpleForm>
			<TextInput
				disabled
				source='id'
				fullWidth={true}
				validate={required()}
			/>
			<TextInput
				source='username'
				fullWidth={true}
				validate={required()}
			/>
			<TextInput source='email' fullWidth={true} validate={required()} />
			<PasswordInput
				label='New Password'
				source='newPassword'
				fullWidth={true}
				validate={required()}
			/>
		</SimpleForm>
	</Edit>
);

const postDefaultValue = () => ({
	password: "asd"
});

export const UserCreate = (props) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();
	const onSuccess = () => {
		notify(`User Added`);
		redirect("/users");
		refresh();
	};
	return (
		<Create onSuccess={onSuccess} {...props}>
			<SimpleForm initialValues={postDefaultValue}>
				<TextInput
					source='username'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='email'
					fullWidth={true}
					validate={required()}
				/>
				<PasswordInput
					source='password'
					fullWidth={true}
					validate={required()}
				/>
			</SimpleForm>
		</Create>
	);
};

const UserShowActions = ({ basePath, data, resource }) => data ? (
	<TopToolbar>
		<DeleteWithConfirmButton
			basePath={basePath}
			record={data}
			resource={resource}
		/>
		<EditButton
			basePath={basePath}
			record={data}
			resource={resource} />
	</TopToolbar>
) : null;

export const UserShow = (props) => (
	<Show {...props} title={<UserTitle />} actions={<UserShowActions />}>
		<SimpleShowLayout>
			<TextField source='id' />
			<TextField source='username' />
			<EmailField source='email' />
		</SimpleShowLayout>
	</Show>
);
