import { Button, Grid, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import "../style.css"

const testimonials = [
  {
    testimonial: [
      "trebal has",
      "significantly improved communication",
      "between my office and my constituents. It has",
      "saved time and money",
      ", while ",
      "improving efficiency and effectiveness.",
      "It is a great asset to any government office!",
    ],
    from: "Park Cannon",
    desgination:
      "Member of the Georgia House of Representatives from the 58th district",
  },
]
const HomeContainer = () => {
  const [testimonial, setTestimonial] = useState(
    testimonials[Math.floor(Math.random() * testimonials.length)]
  )
  useEffect(() => {
    document.querySelector("#root").style.backgroundImage = "none"
    setTestimonial(
      testimonials[Math.floor(Math.random() * testimonials.length)]
    )
    return () => {
      document.querySelector("#root").style.backgroundImage =
        'url("/images/Atlanta_Downtown_Skyline.jpg")'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log(
    testimonial,
    testimonials[Math.floor(Math.random() * testimonials.length)]
  )
  return (
    <>
      <Helmet>
        <title>trebal</title>
      </Helmet>
      <Grid container className="header">
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} className="content">
              <Typography variant="h1">trebal</Typography>
              <Typography component="p">
                Are you an elected official seeking to collect, organize, and
                respond efficiently and effectively to feedback and requests
                from your constituents? trebal pairs a customizable front end
                page with a back end ticketing system, and simplifies the
                process of communication with local, state, and federal
                officials. <br />
                <Button color="secondary" type="submit" variant="contained">
                  <Link to="/ga58">Reach out to Park Cannon</Link>
                </Button>{" "}
                <br />
                Want to learn more?
                <br />
                We would love to hear from you. Reach out to us at{" "}
                <a href="mailto:services@trebal.app">services@trebal.app</a>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className="content">
              {testimonial && (
                <div class="blockquote-wrapper">
                  <div class="blockquote">
                    <Typography variant="h5">
                      {testimonial.testimonial.map((sent, Idx) =>
                        Idx % 2 === 0 ? (
                          <>{sent}</>
                        ) : (
                          <>
                            {" "}
                            <span
                              style={{
                                color: "#ffffff",
                              }}
                            >
                              {sent}
                            </span>{" "}
                          </>
                        )
                      )}
                    </Typography>
                    <Typography variant="h6">
                      &mdash;{testimonial.from}
                      <br />
                      <em>{testimonial.desgination}</em>
                    </Typography>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* <div>
					<svg
						className='waves'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 24 150 28'
						preserveAspectRatio='none'
						shape-rendering='auto'
					>
						<defs>
							<path
								id='gentle-wave'
								d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
							/>
						</defs>
						<g className='parallax'>
							<use
								xlinkHref='#gentle-wave'
								x='48'
								y='0'
								fill='rgba(0,172,193,0.7)'
							/>
							<use
								xlinkHref='#gentle-wave'
								x='48'
								y='3'
								fill='rgba(0,172,193,0.5)'
							/>
							<use
								xlinkHref='#gentle-wave'
								x='48'
								y='5'
								fill='rgba(0,172,193,0.3)'
							/>
							<use
								xlinkHref='#gentle-wave'
								x='48'
								y='7'
								fill='rgba(0,172,193,0.1)'
							/>
						</g>
					</svg>
				</div> */}
      </Grid>
    </>
  )
}

export default HomeContainer
