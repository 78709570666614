import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import UserIcon from "@material-ui/icons/AccountCircle"
import SupplierIcon from "@material-ui/icons/ContactMailOutlined"
import DemandIcon from "@material-ui/icons/EmojiPeopleOutlined"
import IssueIcon from "@material-ui/icons/HowToRegOutlined"
import InstaPostIcon from "@material-ui/icons/PhotoLibrary"
import { Admin, AppBar, Layout, Resource } from "react-admin"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import authProvider from "../components/AdminSection/authProvider"
import Dashboard from "../components/AdminSection/dashboard"
import { useEffect } from "react"

import {
  DemandCreate,
  DemandEdit,
  DemandList,
  DemandShow,
} from "../components/AdminSection/demand"
import {
  InstaPostCreate,
  InstaPostEdit,
  InstaPostList,
  InstaPostShow,
} from "../components/AdminSection/instaPost"
import {
  IssueCreate,
  IssueEdit,
  IssueList,
  IssueShow,
} from "../components/AdminSection/issue"
import {
  SupplierCreate,
  SupplierEdit,
  SupplierList,
  SupplierShow,
} from "../components/AdminSection/supplier"
import {
  UserCreate,
  UserEdit,
  UserList,
  UserShow,
} from "../components/AdminSection/user"
import LoginPage from "../components/LoginPage/index.tsx"
import dataProvider from "../dataProvider"

const provider = window.location.pathname.split("#")[0].split("/")[1]

const AdminSection = styled.section`
  width: 100vw;
`

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  visible: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const CustomAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar {...props} elevation={1}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Typography
        variant="h6"
        component="span"
        color="inherit"
        className={classes.visible}
      >
        trebal
      </Typography>
      <span className={`${classes.spacer} ${classes.visible}`} />
    </AppBar>
  )
}

const AdminContainer = () => {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/${provider}/demands/profaneCount`, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((resp) => {
        if (!resp.ok) {
          window.location.href = `/${provider}/admin#/login`
        }
        return resp.json()
      })
      .catch((error) => {
        console.error("Error", error)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>trebal: {provider} Admin</title>
      </Helmet>
      <AdminSection>
        <Admin
          title="My Custom Admin"
          dataProvider={dataProvider}
          authProvider={authProvider}
          loginPage={LoginPage}
          dashboard={Dashboard}
          layout={(props) => <Layout {...props} appBar={CustomAppBar} />}
        >
          <Resource
            name="demands"
            options={{ label: "Demands" }}
            icon={DemandIcon}
            list={DemandList}
            edit={DemandEdit}
            show={DemandShow}
            create={DemandCreate}
          />
          <Resource
            name="suppliers"
            options={{ label: "Suppliers" }}
            icon={SupplierIcon}
            list={SupplierList}
            edit={SupplierEdit}
            show={SupplierShow}
            create={SupplierCreate}
          />
          <Resource
            name="issues"
            options={{ label: "Issues" }}
            icon={IssueIcon}
            list={IssueList}
            edit={IssueEdit}
            show={IssueShow}
            create={IssueCreate}
          />
          <Resource
            name="instaPosts"
            options={{ label: "InstaPosts" }}
            icon={InstaPostIcon}
            list={InstaPostList}
            edit={InstaPostEdit}
            show={InstaPostShow}
            create={InstaPostCreate}
          />
          <Resource
            name="users"
            options={{ label: "Users" }}
            icon={UserIcon}
            list={UserList}
            edit={UserEdit}
            show={UserShow}
            create={UserCreate}
          />
        </Admin>
      </AdminSection>
    </>
  )
}

export default AdminContainer
