import React from "react"
import { createBootstrapComponent } from "react-bootstrap/esm/ThemeProvider"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Form from "./components/Form"
import NewForm from "./components/Form/NewForm"
// import Recommendations from "./components/Recommendations";
import { Admin, Home, Temp } from "./container"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/ga58" exact component={NewForm} />
          {/* <Route path="/ga58/suppliers" exact component={Form} /> */}
          {/* <Route
						path='/ga58/recommendations'
						exact
						component={Recommendations}
					/> */}
          <Route path="/:provider/admin" exact component={Admin} />
          <Route path="/temp" exact component={Temp} />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App
