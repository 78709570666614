import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import Inbox from "@material-ui/icons/Inbox";
import {
	required,
	useGetResourceLabel,
	useListContext,
	useNotify,
	useRedirect,
	useRefresh,
	useResourceContext,
	useTranslate
} from "ra-core";
import React, { useEffect, useState } from "react";
import {
	AutocompleteInput,
	BulkDeleteWithConfirmButton, Create,
	CreateButton,
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	EditButton,
	ExportButton, FunctionField,
	List,
	Pagination,
	ReferenceField,
	ReferenceInput,
	SaveButton,
	SelectInput,
	Show,
	SimpleForm,
	SimpleList,
	SimpleShowLayout, TextField,
	TextInput,
	Toolbar,
	TopToolbar
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";

const useStyles = makeStyles(
	(theme) => ({
		message: {
			textAlign: "center",
			opacity: theme.palette.type === "light" ? 0.5 : 0.8,
			margin: "0 1em",
			color:
				theme.palette.type === "light"
					? "inherit"
					: theme.palette.text.primary,
		},
		icon: {
			width: "9em",
			height: "9em",
		},
		toolbar: {
			textAlign: "center",
			marginTop: "2em",
			display: "flex",
			justifyContent: "space-evenly",
		},
	}),
	{ name: "RaEmpty" }
);

const Empty = (props) => {
	const { basePath, hasCreate } = useListContext(props);
	const resource = useResourceContext(props);
	const classes = useStyles(props);
	const translate = useTranslate();

	const getResourceLabel = useGetResourceLabel();
	const resourceName = translate(`resources.${resource}.forcedCaseName`, {
		smart_count: 0,
		_: getResourceLabel(resource, 0),
	});

	const emptyMessage = translate("ra.page.empty", { name: resourceName });
	const inviteMessage = translate("ra.page.invite");
	return (
		<>
			<div className={classes.message}>
				<Inbox className={classes.icon} />
				<Typography variant='h4' paragraph>
					{emptyMessage}
				</Typography>
				{hasCreate && (
					<Typography variant='body1'>{inviteMessage}</Typography>
				)}
			</div>
			{hasCreate && (
				<div className={classes.toolbar}>
					<CreateButton variant='contained' basePath={basePath} />
					<ImportButton
						variant='contained'
						{...props}
						parseConfig={{ dynamicTyping: true }}
					/>
				</div>
			)}
		</>
	);
};

const SupplierListActions = (props) => {
	const {
		className,
		basePath,
		total,
		resource,
		currentSort,
		filterValues,
		exporter,
	} = props;
	const classes = useListStyles();
	const [count, setCount] = useState(0);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API}/${window.location.pathname.split('#')[0].split('/')[1]}/suppliers/profaneCount`)
			.then((resp) => resp.json())
			.then((data) => {
				setCount(data);
			});
	});

	return (
		<TopToolbar className={className}>
			<div className={classes.stats}>
				<p>Profane Count: {count}</p>
			</div>
			<CreateButton basePath={basePath} />
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={filterValues}
				exporter={exporter}
			/>
			<ImportButton {...props} parseConfig={{ dynamicTyping: true }} />
		</TopToolbar>
	);
};

const useListStyles = makeStyles({
	wrap: {
		maxWidth: "10em",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	stats: {
		height: "30px",
		display: "flex",
		alignContent: "center",
	},
});

export const SupplierList = ({ permissions, ...props }) => {
	const classes = useListStyles();
	const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	return (
		<List
			{...props}
			pagination={<Pagination rowsPerPageOptions={[]} />}
			perPage={10}
			empty={<Empty />}
			actions={<SupplierListActions {...props} />}
			bulkActionButtons={<BulkDeleteWithConfirmButton />}
		>
			{isXSmall ? (
				<SimpleList
					primaryText={(record) => (
						<ReferenceField
							basePath='suppliers'
							record={record}
							label='Issue'
							source='issue'
							reference='issues'
							linkType='show'
						>
							<TextField source='title' />
						</ReferenceField>
					)}
					secondaryText={(record) =>
						record.desc.length > 20
							? `${record.desc.slice(0, 20)}...`
							: record.desc
					}
				/>
			) : (
				<Datagrid rowClick='show'>
					<TextField source='id' />
					<FunctionField
						render={(record) => `${record.fname} ${record.lname}`}
						label='Name'
						cellClassName={classes.wrap}
					/>
					<ReferenceField
						label='Issue'
						source='issue'
						reference='issues'
						link='show'
						fullWidth={true}
					>
						<TextField source='title' />
					</ReferenceField>
					<TextField source='desc' cellClassName={classes.wrap} />
					<TextField source='status' cellClassName={classes.wrap} />
					<EditButton
						base='/suppliers'
						label={isSmall ? "" : "Edit"}
					/>
					<DeleteWithConfirmButton
						base='/suppliers'
						label={isSmall ? "" : "Delete"}
					/>
				</Datagrid>
			)}
		</List>
	);
};

const SupplierTitle = ({ record }) => {
	return <span>Supplier #{record ? `${record.id}` : ""}</span>;
};

const useToolbarStyles = makeStyles({
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
	},
});

const EditToolbar = (props) => {
	const classes = useToolbarStyles();
	return (
		<Toolbar {...props} classes={classes}>
			<SaveButton />
			<DeleteWithConfirmButton />
		</Toolbar>
	);
};

export const SupplierEdit = (props) => {
	return (
		<Edit title={<SupplierTitle />} {...props}>
			<SimpleForm toolbar={<EditToolbar />}>
				<TextInput
					source='fname'
					label='First Name'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='lname'
					label='Last Name'
					fullWidth={true}
					validate={required()}
				/>
				<ReferenceInput
					label='Issue'
					source='issue'
					reference='issues'
					fullWidth={true}
				>
					<AutocompleteInput optionText='title' />
				</ReferenceInput>
				<TextInput
					multiline={true}
					source='desc'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='email'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='phone'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					multiline={true}
					source='zipcode'
					fullWidth={true}
					validate={required()}
				/>
				<SelectInput
					fullWidth={true}
					source='status'
					choices={[
						{ id: "Available", name: "Available" },
						{ id: "Occupied", name: "Occupied" },
					]}
				/>
				<TextInput multiline={true} source='notes' fullWidth={true} />
			</SimpleForm>
		</Edit>
	);
};

export const SupplierCreate = (props) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();
	const onSuccess = () => {
		notify(`Supplier Added`);
		redirect("/suppliers");
		refresh();
	};
	return (
		<Create onSuccess={onSuccess} {...props}>
			<SimpleForm>
				<TextInput
					source='fname'
					label='First Name'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='lname'
					label='Last Name'
					fullWidth={true}
					validate={required()}
				/>
				<ReferenceInput
					label='Issue'
					source='issue'
					reference='issues'
					fullWidth={true}
				>
					<AutocompleteInput optionText='title' />
				</ReferenceInput>
				<TextInput
					multiline={true}
					source='desc'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='email'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					source='phone'
					fullWidth={true}
					validate={required()}
				/>
				<TextInput
					multiline={true}
					source='zipcode'
					fullWidth={true}
					validate={required()}
				/>
				<SelectInput
					fullWidth={true}
					source='status'
					choices={[
						{ id: "Available", name: "Available" },
						{ id: "Occupied", name: "Occupied" },
					]}
				/>
				<TextInput multiline={true} source='notes' fullWidth={true} />
			</SimpleForm>
		</Create>
	);
};

export const SupplierShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source='fname' label='First Name' fullWidth={true} />
			<TextField source='lname' label='Last Name' fullWidth={true} />
			<ReferenceField
				label='Issue'
				source='issue'
				reference='issues'
				link='show'
				fullWidth={true}
			>
				<TextField source='title' />
			</ReferenceField>
			<TextField multiline='true' source='desc' fullWidth={true} />
			<TextField source='email' fullWidth={true} />
			<TextField source='phone' fullWidth={true} />
			<TextField source='zipcode' fullWidth={true} />
			<TextField source='status' fullWidth={true} />
			<TextField source='notes' fullWidth={true} />
			<TextField source='profanes' fullWidth={true} />
		</SimpleShowLayout>
	</Show>
);
